<template>
  <div class="tab-pane active" id="rcs-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <Chart
            ref="statusChart"
            title="RCS por Status"
            :data="statusReport.data"
            :loading="statusReport.loading"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <Chart
            ref="agentsChart"
            title="RCS por Agente"
            :data="agentReport.data"
            :loading="agentReport.loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Chart from '@/components/rebranding/Chart.vue';

export default {
  name: 'RcsStats',
  components: {
    Chart,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      statusReport: {
        loading: true,
        data: [],
      },
      emptyData: [
        { group_by: 'Lido', values: [['', 0]] },
        { group_by: 'Entregue', values: [['', 0]] },
        { group_by: 'Falha', values: [['', 0]] },
        { group_by: 'Optout', values: [['', 0]] },
        { group_by: 'Enviada', values: [['', 0]] },
      ],
      agentReport: {
        loading: true,
        data: [],
      },
      statusRcs: [],
      channelRcs: [],
      loading: true,
      inbound: 0,
      outbound: 0,
      failures: 0,
      charged: 0,
    };
  },
  computed: {
    timeWindow() {
      if (moment(this.endDate).diff(moment(this.startDate), 'days')) {
        return 'day';
      }
      return 'hour';
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (this.$route.params.campaignId) {
        this.filters.campaign_id = this.$route.params.campaignId;
      }
      this.fetchStatusMessages(this.filters);
      this.fetchAgentMessages(this.filters);
    },
    fetchStatusMessages(filter) {
      this.statusReport.loading = true;
      this.$store
        .dispatch('fetchAdminRcsInsights', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'status',
          filter,
        })
        .then((response) => {
          this.statusReport.data = response.map((insight) => {
            insight.group_by = this.$tc(
              `app.${insight.group_by.toLowerCase()}`,
            );
            return insight;
          });
          if (this.statusReport.data.length === 0) {
            this.statusReport.data = this.emptyData;
          }
        })
        .finally(() => {
          this.statusReport.loading = false;
        });
    },
    fetchAgentMessages(filter) {
      this.agentReport.loading = true;
      this.$store
        .dispatch('fetchAdminRcsInsights', {
          start_date: this.startDate,
          end_date: this.endDate,
          time_window: this.timeWindow,
          group_by: 'agent_id',
          filter,
        })
        .then((response) => {
          this.agentReport.data = response;
          if (this.agentReport.data.length === 0) {
            this.agentReport.data = this.emptyData;
          }
        })
        .finally(() => {
          this.agentReport.loading = false;
        });
    },
    exportData(format) {
      this.$refs.statusChart.exportData(format);
      this.$refs.agentsChart.exportData(format);
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-column-right {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
}
.card-messages {
  /* margin-left: auto; */
  max-height: 230px;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--clr-light-2);
      font-weight: bold;
    }
  }
}
#infos-bottom,
#infos-top {
  position: relative;
  z-index: 1;
  min-width: 260px;
}
.card-messages.inner-card {
  /* position: absolute;
  right: 0; */
  /* opacity: 0; */
  z-index: 1;
}
.card-messages.inner-card.active {
  /* position: absolute;
  right: 325px; */
  z-index: 0;
  /* opacity: 1; */
}
/* .card-messages.inner-card.active:last-of-type {
  right: 470px;
} */

.darkmode .card {
  background-color: var(--background-3);
}
</style>
